<div class="container body-container">
  <div class="main-container">
    <h3>Track Your Shipment</h3>
    <hr />

    <div class="row" *ngIf="!loader && status">
      <div class="col-md-6" id="track-column-1">

        <div *ngIf="!courier.Mode">
          <table class="table table-hover">
            <tbody>
              <tr>
                <th scope="row">AWB Number</th>
                <td>{{ trackResult.awbNumber }}</td>
              </tr>
              <tr>
                <th scope="row">Reference Number</th>
                <td>{{ trackResult.referenceNumber }}</td>
              </tr>
              <tr>
                <th scope="row">Booked Date</th>
                <td>{{ trackResult.bookedDate | date:'medium' }}</td>
              </tr>
              <tr>
                <th scope="row">Origin</th>
                <td>{{ trackResult.origin }}</td>
              </tr>
              <tr>
                <th scope="row">Destination</th>
                <td>{{ trackResult.destination }}</td>
              </tr>
              <tr>
                <th scope="row">Package Type</th>
                <td>{{ getDoxType(trackResult.doxType) }}</td>
              </tr>
              <tr>
                <th scope="row">Shipment Mode</th>
                <td>{{ getShipmentMode(trackResult.shipmentMode) }}</td>
              </tr>
              <tr>
                <th scope="row">Transport Mode</th>
                <td>
                  <span
                    *ngIf="getTransportMode(trackResult.transportMode).toLowerCase().includes('surface'); else elseBlock"
                    class="fw-bold fs-6">{{ getTransportMode(trackResult.transportMode) }}</span>
                  <ng-template #elseBlock>{{ getTransportMode(trackResult.transportMode) }}</ng-template>
                </td>
              </tr>
              <tr>
                <th scope="row">Received By</th>
                <td>{{ trackResult.receivedPerson }}</td>
              </tr>
              <tr>
                <th scope="row">Relation</th>
                <td *ngIf="trackResult.receivedPersonRelation  && trackResult.receivedPersonRelation !== 'NULL'">{{
                  getStatusRelation(trackResult.receivedPersonRelation) }}</td>
                <td *ngIf="!(trackResult.receivedPersonRelation  && trackResult.receivedPersonRelation !== 'NULL')">
                </td>
              </tr>
              <tr>
                <th scope="row">Delivery Office Location</th>
                <td>{{ trackResult.deliveryOfficeAddress }}</td>
              </tr>
              <tr>
                <th scope="row">Remarks</th>
                <td class="fw-bold fs-6">{{ trackResult.remarks }}</td>
              </tr>
              <tr>
                <th scope="row">Courier</th>
                <td>{{ getCourierName(trackResult.courier) }}</td>
              </tr>
              <tr>
                <th scope="row">Website</th>
                <td>{{ getCourierUrl(trackResult.courier) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="courier.Mode === 2">
          <table class="table table-hover">
            <tr>
              <th scope="row">AWB Number</th>
              <td>{{ trackResult.awbNumber }}</td>
            </tr>
            <tr>
              <th scope="row">Reference Number</th>
              <td>{{ trackResult.referenceNumber }}</td>
            </tr>
            <tr>
              <th scope="row">Booked Date</th>
              <td>{{ trackResult.bookedDate | date:'medium' }}</td>
            </tr>
            <tr>
              <th scope="row">Remarks</th>
              <td class="fw-bold fs-8">{{ trackResult.remarks }}</td>
            </tr>
            <tr>
              <th scope="row">Tracking Details</th>
              <td class="fw-bold fs-8">

                <a *ngIf="trackResult.referenceNumber; else refNotFoundBlock"
                  href="{{getCourierUrl(trackResult.courier).replace('<AWB>', trackResult.referenceNumber)}}"
                  target="_blank">Click here for details
                </a>
                <ng-template #refNotFoundBlock>Please contact us for reference number!</ng-template>
              </td>
            </tr>

          </table>
        </div>

        <!-- <div *ngIf="courier.Mode === 3 && courierAPIResult">
          <h2>Shipment Details</h2>
          <table class="table table-hover">
            <tbody>
              <tr>
                <th>Waybill Number</th>
                <td>{{ courierAPIResult.ShipmentData.Shipment[0].$.WaybillNo }}</td>
              </tr>
              <tr>
                <th>Service</th>
                <td>{{ courierAPIResult.ShipmentData.Shipment[0].Service[0] }}</td>
              </tr>
              <tr>
                <th>Pick Up Date</th>
                <td>{{ courierAPIResult.ShipmentData.Shipment[0].PickUpDate[0] }}</td>
              </tr>
              <tr>
                <th>Pick Up Time</th>
                <td>{{ courierAPIResult.ShipmentData.Shipment[0].PickUpTime[0] }}</td>
              </tr>
              <tr>
                <th>Origin</th>
                <td>{{ courierAPIResult.ShipmentData.Shipment[0].Origin[0] }}</td>
              </tr>
              <tr>
                <th>Destination</th>
                <td>{{ courierAPIResult.ShipmentData.Shipment[0].Destination[0] }}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{{ courierAPIResult.ShipmentData.Shipment[0].Status[0] }}</td>
              </tr>
              <tr>
                <th>Expected Delivery Date</th>
                <td>{{ courierAPIResult.ShipmentData.Shipment[0].ExpectedDeliveryDate[0] }}</td>
              </tr>
            </tbody>
          </table>

          <h4>Status History</h4>
          <table class="table table-hover" *ngIf="courierAPIResult.ShipmentData?.Shipment[0].Status">
            <thead>
              <tr>
                <th>Status Date</th>
                <th>Status Time</th>
                <th>Status</th>
                <th>Received By</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let scanDetail of courierAPIResult.ShipmentData.Shipment[0].Scans[0].ScanDetail">
                <td>{{ scanDetail.ScanDate[0] }}</td>
                <td>{{ scanDetail.ScanTime[0] }}</td>
                <td>{{ scanDetail.Scan[0] }}</td>
                <td>{{ scanDetail.ScannedLocation[0] }}</td>
              </tr>
            </tbody>
          </table>

        </div>

        <div *ngIf="courier.Mode === 3 && !courierAPIStatus">
          Unable to get the shipment details from {{courier?.Courier}}. Please check bill number or reference number.
        </div> -->

      </div>

      <div class="col-md-6" *ngIf="!courier.Mode">
        <div id="tracking-pre"></div>
        <div id="tracking">
          <div class="text-center tracking-status-intransit">
            <p class="tracking-status text-tight">{{statusList[statusList.length - 1].status}}</p>
          </div>
          <div class="tracking-list">
            <div class="tracking-item" *ngFor="let status of statusList">
              <div class="tracking-icon status-intransit">
                <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle"
                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                  <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z">
                  </path>
                </svg>
              </div>
              <div class="tracking-date">{{ status.statusDate }}<span> {{ status.statusTime}} </span></div>
              <div class="tracking-content"> {{ status.status}} <span> {{ status.remark }} </span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="loader">
      <p>Status Loading...</p>
    </div>

    <div class="row" *ngIf="!loader && !status">
      <p><strong>Provided AWB/Reference Number is invalid. Please check the air way bill number or contact us.</strong>
      </p>
    </div>
  </div>
</div>